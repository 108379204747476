ol {
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: black;
    color: white;
}

li {
    margin: 0px 2px;
}

.tabComponent {
    width: 75%;
    background-color: #eee;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.tabComponent > * {
    flex-shrink: 0;
    flex-basis: auto;
}
