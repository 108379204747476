.about {
    width: calc(100% - 80px);
    margin: auto;
}

.about > h1 {
    margin: 0px;
    padding: 20px;
}

.aboutInfo > img {
    float: left;
    width: 400px;
    max-width: 25%;
    margin-bottom: 20px;
}

.aboutInfo > p {
    overflow: auto;
    padding: 10px;
}
