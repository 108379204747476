.entry {
    padding: 20px auto;
    margin: 40px auto 0px;
    width: calc(100% - 80px);
    background-color: rgb(186, 223, 238);
    border-radius: 5px;
    box-shadow: 5px 5px 5px #333;
}

.entry:last-child {
    margin-bottom: 40px;
}
