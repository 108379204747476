.tab {
    display: inline-block;
    padding: 10px;
    margin: 0px 2px;
    border-bottom: 4px solid #3a6dc0;
    cursor: pointer;
}

.tab-active {
    border-bottom: 4px solid skyblue;
}

.tab > h3 {
    margin: 0px;
}
